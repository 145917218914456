import styled from 'styled-components'

export const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5em;
  width: 100%;
`
