import React from 'react'
import _ from 'lodash'
import {
  Container,
  ItemContainer,
  ItemHeader,
  ErrorsContainer,
  DocumentName,
  Errors,
  Actions,
  Trace,
  TraceHolder,
} from './styled/JobResult.styled'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCheck, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import Button from '../Common/Button'
import EmsError from '../EmsError/EmsError'
import {EmployeeTable} from '../EmployeeData/EmployeeTable'

const JobResult = ({
  success,
  failedFiles,
  message,
  diff,
  errors,
  actions = [['Continue', () => window.location.reload()]],
  title,
  stackTrace,
}) => {
  return (
    <Container>
      {errors && errors.length > 0 && <EmsError errors={errors} />}
      <h2>{title || (success ? 'Success' : 'Error')}</h2>

      {message && <p>{message}</p>}

      {!success &&
        failedFiles &&
        failedFiles.map(({document, errors}) => (
          <ItemContainer key={document}>
            <ItemHeader>
              <DocumentName>{document}</DocumentName>
              <FontAwesomeIcon
                color={errors ? 'red' : 'green'}
                icon={errors ? faExclamationTriangle : faCheck}
              />
            </ItemHeader>

            {errors && (
              <ErrorsContainer>
                <Errors>
                  <thead>
                    <tr>
                      <th>Row / Id</th>
                      <th>Error</th>
                    </tr>
                  </thead>

                  <tbody>
                    {errors.map(({id, message}) => {
                      if (!Array.isArray(id)) id = [id]
                      const [key, url] = id
                      const link =
                        url == null ? (
                          key
                        ) : (
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {key}
                          </a>
                        )
                      return (
                        <tr key={key}>
                          <td>{link}</td>
                          <td>{message}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Errors>
              </ErrorsContainer>
            )}
          </ItemContainer>
        ))}

      {_.map(diff, ({diff, header}, title) => (
        <React.Fragment key={title}>
          <h2>{title}</h2>
          <EmployeeTable diff={diff} header={header} />
        </React.Fragment>
      ))}
      {stackTrace && (
        <TraceHolder>
          <Trace>{stackTrace}</Trace>
        </TraceHolder>
      )}
      <Actions>
        {actions.map(([name, fn]) => (
          <Button key={name} onClick={fn}>
            {name}
          </Button>
        ))}
      </Actions>
    </Container>
  )
}

export default JobResult
