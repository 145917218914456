import React, {useState, useContext} from 'react'
import _ from 'lodash'
import axios from 'axios'
import Button from '../Common/Button'
import YearMonthInput, {monthBefore} from '../Common/YearMonthInput'
import {
  Container,
  Column,
  Row,
  YearMonthLabel,
} from './styled/EmployeeData.styled'
import {ModalContext} from '../Common/Modal'
import ConfirmationModalBody from '../Common/ConfirmationModalBody'

const getYearMonth = (yearMonth) => {
  const [year, month] = yearMonth

  return `${year}-${month.toString().padStart(2, '0')}`
}

const EmployeeApproval = ({setIsSubmitting, setJobResult}) => {
  const {closeModal, setModalChildren} = useContext(ModalContext)
  const [emsFromDate, setEmsFromDate] = useState(monthBefore())
  const [emsToDate, setEmsToDate] = useState(monthBefore())
  const [jiraDate, setJiraDate] = useState(monthBefore())

  const confirm = async (confirmationId) => {
    setIsSubmitting(true)
    const {success, message, stackTrace} = (
      await axios.post(`/api/confirmation/${confirmationId}`)
    ).data
    setJobResult({
      success,
      message,
      stackTrace,
    })
    setIsSubmitting(false)
  }

  const getActions = (confirmationId) => {
    if (confirmationId != null) {
      const openConfirmationModal = () => {
        const onConfirm = () => {
          confirm(confirmationId)
          closeModal()
        }
        setModalChildren(
          <ConfirmationModalBody closeModal={closeModal} confirm={onConfirm} />,
        )
      }

      return [
        ['Confirm overwrite', openConfirmationModal],
        ['Cancel', () => window.location.reload()],
      ]
    }
    return undefined
  }

  const getJobData = ({success, diff, confirmationId, message}) => {
    const actions = getActions(confirmationId)
    const hasDifferences = _.some(diff, (d) =>
      _.some(d, (details) => !_.isEmpty(details)),
    )
    const title = hasDifferences && 'Warning'
    const errorMessage =
      hasDifferences || !success
        ? message || 'There are differences between database and EMS/Jira'
        : message || 'Nothing has Changed'

    return {
      success,
      title,
      message: errorMessage,
      diff,
      actions,
    }
  }

  const loadEmployeeData = async (body, apiRoute) => {
    setIsSubmitting(true)

    const data = (await axios.post(apiRoute, body)).data

    setJobResult(getJobData(data))
    setIsSubmitting(false)
  }

  const loadEmsData = async () => {
    const fromDate = getYearMonth(emsFromDate)
    const toDate = getYearMonth(emsToDate)
    await loadEmployeeData(
      {from: fromDate, to: toDate},
      '/api/v2/employees/approve-details',
    )
  }

  const loadJiraData = async () => {
    const date = getYearMonth(jiraDate)
    await loadEmployeeData({month: date}, '/api/v2/employees/approve-worklogs')
  }

  const [emsYearFrom, emsMonthFrom] = emsFromDate
  const [emsYearTo, emsMonthTo] = emsToDate
  const [jiraYear, jiraMonth] = jiraDate

  return (
    <Container>
      <h1>Load & Approve EMS data</h1>
      <Row>
        <Column>
          <Row>
            <YearMonthLabel>From: </YearMonthLabel>
            <YearMonthInput
              year={emsYearFrom}
              month={emsMonthFrom}
              setYearMonth={setEmsFromDate}
            />
          </Row>
          <Row>
            <YearMonthLabel>To: </YearMonthLabel>
            <YearMonthInput
              year={emsYearTo}
              month={emsMonthTo}
              setYearMonth={setEmsToDate}
            />
          </Row>
        </Column>
        <Column>
          <Button onClick={loadEmsData}>Check changes</Button>
        </Column>
      </Row>
      <h1>Load & Approve Jira worklogs</h1>
      <Row>
        <Column>
          <Row>
            <YearMonthLabel>Month: </YearMonthLabel>
            <YearMonthInput
              year={jiraYear}
              month={jiraMonth}
              setYearMonth={setJiraDate}
            />
          </Row>
        </Column>
        <Column>
          <Button onClick={loadJiraData}>Check changes</Button>
        </Column>
      </Row>
    </Container>
  )
}

export default EmployeeApproval
