import React from 'react'
import {StyledButton} from './styled/Button.styled'

const Button = ({children, onClick, disabled, type = 'primary'}) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled} type={type}>
      {children}
    </StyledButton>
  )
}

export default Button
