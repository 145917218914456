import React, {useState, useEffect} from 'react'
import {useLocation} from 'react-router-dom'
import axios from 'axios'
import Button from '../Common/Button'
import InputGroup from '../Common/InputGroup'
import Accounts from './Accounts'
import {Container} from './styled/Authorized.styled'

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const AuthorizePage = () => {
  const query = useQuery()

  const [clientId, setClientId] = useState('')
  const [accountName, setAccountName] = useState('')
  const [message, setMessage] = useState(null)
  const [accounts, setAccounts] = useState([])

  const authorizationCode = query.get('code')

  const loadExistingAccounts = async () => {
    const {accounts} = (await axios.get('/api/revolut/accounts')).data
    setAccounts(accounts)
  }

  useEffect(() => {
    loadExistingAccounts()
  }, [accounts.length])

  const areInputsFilled = () => {
    return clientId !== '' && accountName !== ''
  }

  const authorize = async () => {
    if (!areInputsFilled()) {
      setMessage('Please fill in account name and client id')
      return
    }

    setMessage('Loading')

    const result = (
      await axios.post(
        `/api/revolut/authorize?authorizationCode=${authorizationCode}&account_name=${accountName}&client_id=${clientId}`,
      )
    ).data

    const resultMessage = result.success
      ? 'API authorized. You may close this window.'
      : `An error occured. Please try again. Error: ${result.message}`

    setMessage(resultMessage)
    setAccountName('')
    setClientId('')

    loadExistingAccounts()
  }

  if (!authorizationCode) {
    return <h1>Authorization code parameter is missing</h1>
  }

  return (
    <>
      <Container>
        <div>
          <h1>Authorize Revolut API</h1>
          <InputGroup
            label={'Account name'}
            name={'account-name'}
            value={accountName}
            placeholder={'You can choose anything'}
            onChange={setAccountName}
          />
          <InputGroup
            label={'Client ID'}
            name={'client-id'}
            value={clientId}
            placeholder={'Client ID from Revolut'}
            onChange={setClientId}
          />
          <Button onClick={authorize}>Authorize</Button>
        </div>
        <Accounts accounts={accounts} onAccountDeleted={loadExistingAccounts} />
      </Container>
      <div>
        {message && <h1>{message}</h1>}
        <h1>How to find client id</h1>
        <img
          src={'/img/revolutClientId.gif'}
          alt={'How to find client id'}
          width={750}
        />
      </div>
    </>
  )
}

export default AuthorizePage
