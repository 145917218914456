import React from 'react'
import axios from 'axios'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import {
  Table,
  TableRow,
  TableData,
  Container,
  SmallP,
} from './styled/Accounts.styled'

const Accounts = ({accounts, onAccountDeleted}) => {
  const deleteAccount = async (name) => {
    await axios.delete(`/api/revolut/accounts?name=${name}`)
    onAccountDeleted()
  }

  return (
    <Container>
      <h1>Existing accounts</h1>
      <SmallP>
        Authorizing an account with an existing name will overwrite it
      </SmallP>
      {!accounts || accounts.length === 0 ? (
        <>
          <h2>No Revolut accounts added yet</h2>
          <p>Enable access to Revolut API in your Revolut account settings</p>
        </>
      ) : (
        <Table>
          <TableRow>
            <th>Name</th>
            <th>Client ID</th>
          </TableRow>
          {accounts.map((a) => (
            <TableRow key={a.name}>
              <TableData>{a.name}</TableData>
              <TableData>{a.clientId}</TableData>
              <TableData>
                <i onClick={() => deleteAccount(a.name)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </i>
              </TableData>
            </TableRow>
          ))}
        </Table>
      )}
    </Container>
  )
}

export default Accounts
