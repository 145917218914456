import React from 'react'
import {TextInput, Label, Container} from './styled/InputGroup.styled'

const InputGroup = ({label, name, value, placeholder, onChange}) => {
  return (
    <Container>
      <Label htmlFor={name}>{label}</Label>
      <TextInput
        type="text"
        name={name}
        value={value}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </Container>
  )
}

export default InputGroup
