import React from 'react'
import {
  File,
  InputBox,
  Instructions,
  InstructionText,
} from './Home/styled/HomePage.styled'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faBox, faBoxOpen, faTrashAlt} from '@fortawesome/free-solid-svg-icons'

export const FileUploadInputBox = ({
  getRootProps,
  getInputProps,
  files,
  isDragActive,
  removeFile,
}) => {
  return (
    <InputBox {...getRootProps()}>
      <input {...getInputProps()} />

      {!files.length ? (
        <Instructions>
          <FontAwesomeIcon
            size="10x"
            color="#aaa"
            icon={isDragActive ? faBoxOpen : faBox}
          />

          <InstructionText>Drop files here</InstructionText>
        </Instructions>
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          {files.map((f) => (
            <File key={f.name}>
              <span>{f.name}</span>

              <i onClick={() => removeFile(f)}>
                <FontAwesomeIcon icon={faTrashAlt} />
              </i>
            </File>
          ))}
        </div>
      )}
    </InputBox>
  )
}
