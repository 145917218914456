import React, {useState, useEffect, useContext, createContext} from 'react'

import {Background, Body} from './styled/Modal.styled'

const ModalBackground = ({closeModal, children}) => {
  useEffect(() => {
    const close = (e) => e.key === 'Escape' && closeModal()
    document.addEventListener('keydown', close)
    return () => document.removeEventListener('keydown', close)
  }, [closeModal])

  // only close when clicking on this background, not children
  const close = (e) => e.target === e.currentTarget && closeModal()

  return <Background onClick={close}>{children}</Background>
}

export const ModalContext = createContext()

export const ModalProvider = (props) => {
  const [isVisible, setIsVisible] = useState(false)
  const [children, setChildren] = useState(<div />)

  const closeModal = () => {
    setIsVisible(false)
    setChildren(<div />)
  }

  const setModalChildren = (node) => {
    setChildren(node)
    setIsVisible(true)
  }

  const context = {
    isVisible,
    closeModal,
    modalChildren: children,
    setModalChildren,
  }

  return (
    <ModalContext.Provider value={context}>
      {props.children}
    </ModalContext.Provider>
  )
}

export const Modal = ({children}) => {
  const {isVisible, closeModal, modalChildren} = useContext(ModalContext)
  return (
    <>
      {children}
      {isVisible && (
        <ModalBackground closeModal={closeModal}>
          <Body>{modalChildren}</Body>
        </ModalBackground>
      )}
    </>
  )
}
