import React from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom'
import HomePage from './components/Home/HomePage'
import Layout from './components/Layout/Layout'
import AuthorizePage from './components/Revolut/AuthorizePage'
import {ModalProvider, Modal} from './components/Common/Modal'

const App = () => {
  return (
    <Router>
      <Layout>
        <ModalProvider>
          <Switch>
            <Route path="/revolut/authorize" component={AuthorizePage} />
            <Route path="/" component={HomePage} />
          </Switch>
          <Modal />
        </ModalProvider>
      </Layout>
    </Router>
  )
}

export default App
