import React from 'react'
import Button from '../Common/Button'
import {Container, ButtonContainer} from './styled/DateMismatchModalBody.styled'

const DateMismatchModalBody = ({
  closeModal,
  confirm,
  fileDate,
  selectedDate,
  fileName,
}) => (
  <Container>
    <h2>
      You selected month {selectedDate}, but there is date {fileDate} in the
      file name '{fileName}'.
    </h2>
    <h2>Do you want to continue ?</h2>
    <ButtonContainer>
      <Button onClick={confirm}>Yes</Button>
      <Button onClick={closeModal} type="warning">
        Cancel
      </Button>
    </ButtonContainer>
  </Container>
)

export default DateMismatchModalBody
