import React, {useState, useEffect} from 'react'
import axios from 'axios'
import {Select} from './styled/YearMonthInput.styled'
import {years} from './YearEntityInput'
import {months} from './YearMonthInput'

const YearMonthEntityInput = ({
  year,
  month,
  entity,
  setYearMonthEntity,
  countries,
}) => {
  const [entities, setEntities] = useState([])

  const loadExistingEntities = async (countries) => {
    const result = (await axios.get('/api/entities')).data
    setEntities(
      Object.values(result.entities || {})
        .filter((entity) => countries.includes(entity.country))
        .map((entity) => entity.name)
        .sort((a, b) => a.localeCompare(b)),
    )
  }

  const entitiesLength = (entities || []).length
  useEffect(() => {
    loadExistingEntities(countries)
  }, [entitiesLength, countries])

  return (
    <>
      <Select
        value={year}
        onChange={(e) =>
          setYearMonthEntity([e.target && e.target.value, month, entity])
        }
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
      <Select
        value={month}
        onChange={(e) =>
          setYearMonthEntity([year, e.target && e.target.value, entity])
        }
      >
        {months.map((month, i) => (
          <option key={i + 1} value={i + 1}>
            {month}
          </option>
        ))}
      </Select>
      <Select
        value={entity}
        onChange={(e) =>
          setYearMonthEntity([year, month, e.target && e.target.value])
        }
      >
        {(entities || ['vacuumlabs s.r.o.']).map((entity, i) => (
          <option key={i + 1} value={entity}>
            {entity}
          </option>
        ))}
      </Select>
    </>
  )
}

export default YearMonthEntityInput
