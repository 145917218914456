import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
  justify-content: center;
  align-content: center;
  margin-bottom: 50px;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

export const YearMonthLabel = styled.span`
  margin-right: 0.5em;
`

export const ErrorMessage = styled.p`
  margin-top: 1em;
  color: red;
`
