import React from 'react'
import {EmsErrorContainer} from './styled/EmsError.styled'

const EmsError = ({errors}) => (
  <EmsErrorContainer>
    <h2>There seem to be some problems with EMS data</h2>
    {errors.map((error, i) => (
      <div key={i}>
        <span>{error}</span>
      </div>
    ))}
    <h3>Please write to #ceo-squad, so they can be dealt with immediatelly</h3>
  </EmsErrorContainer>
)

export default EmsError
