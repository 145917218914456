import {Container} from './styled/FileUpload.styled'
import React, {useState, useContext} from 'react'
import {useDropzone} from 'react-dropzone'
import _ from 'lodash'
import parse from 'csv-parse/lib/sync'
import Button from '../Common/Button'
import Actions from '../Common/Actions'
import {Row} from '../EmployeeData/styled/EmployeeData.styled'
import YearMonthInput, {monthBefore} from '../Common/YearMonthInput'
import {submitFiles} from '../../utils'
import axios from 'axios'
import {ModalContext} from '../Common/Modal'
import ConfirmationModalBody from '../Common/ConfirmationModalBody'
import DateMismatchModalBody from './DateMismatchModalBody'
import {FileUploadInputBox} from '../utils'
import YearEntityInput from '../Common/YearEntityInput'
import YearEntityMismatchModalBody from './YearEntityMismatchModalBody'
import YearMonthEntityInput from '../Common/YearMonthEntityInput'
import {
  parsePayslip,
  checkPayslips,
  formatYearMonth,
} from 'ledger-transform-common'
import UploadDataWarningsModalBody from './UploadDataWarningsModalBody'

const currentDate = new Date()

const formatMonth = (year, month) => `${year}-${month.padStart(2, '0')}`

const FileUpload = ({
  setIsSubmitting,
  setJobResult,
  uploadPath,
  deletePath,
  title,
}) => {
  const [files, setFiles] = useState([])
  const [yearMonth, setYearMonth] = useState(monthBefore())
  const {closeModal, setModalChildren} = useContext(ModalContext)

  const addFiles = (newFiles) => {
    setFiles((files) => {
      return _.unionBy(files, newFiles, (x) => x.name)
    })
  }

  const removeFile = (file) => {
    setFiles((files) => {
      return _.without(files, file)
    })
  }

  const onDrop = (acceptedFiles) => {
    addFiles(acceptedFiles)
  }

  const confirm = async (confirmationId) => {
    setIsSubmitting(true)
    const {success, message} = (
      await axios.post(`/api/confirmation/${confirmationId}`)
    ).data
    setJobResult({
      success,
      message,
      diff: undefined,
      header: undefined,
      actions: undefined,
      title: undefined,
    })
    setIsSubmitting(false)
  }

  const getActions = (confirmationId) => {
    if (confirmationId !== null) {
      const openConfirmationModal = () => {
        const onConfirm = () => {
          confirm(confirmationId)
          closeModal()
        }
        setModalChildren(
          <ConfirmationModalBody closeModal={closeModal} confirm={onConfirm} />,
        )
      }

      return [
        ['Confirm overwrite', openConfirmationModal],
        ['Cancel', () => window.location.reload()],
      ]
    }
    return undefined
  }

  const submit = async () => {
    const [selectedYear, selectedMonth] = yearMonth
    const month = formatMonth(...yearMonth)
    const fileMonth = files
      .map((file) => [file.name.match(/\d{4}-\d{1,2}/), file.name])
      .filter(([match, _fileName]) => match != null)
      .map(([match, fileName]) => {
        const [fileYear, fileMonth] = match[0].split('-').map(Number)
        return [[fileYear, fileMonth], fileName]
      })
      .find(
        ([[fileYear, fileMonth], _fileName]) =>
          fileMonth !== Number(selectedMonth) ||
          fileYear !== Number(selectedYear),
      )
    const proceed = async () => {
      setIsSubmitting(true)
      const response = (
        await submitFiles(files, 'monthly-statement', `${uploadPath}${month}`)
      ).data
      const actions = getActions(response.confirmationId)
      setJobResult({...response, actions})
      setIsSubmitting(false)
      closeModal()
    }

    if (fileMonth != null) {
      const [mismatchedMonth, fileName] = fileMonth
      setModalChildren(
        <DateMismatchModalBody
          confirm={proceed}
          closeModal={closeModal}
          fileDate={mismatchedMonth.join('-')}
          selectedDate={month}
          fileName={fileName}
        />,
      )
    } else {
      await proceed()
    }
  }

  const deleteHardware = async () => {
    const month = formatMonth(...yearMonth)
    await axios.delete(`${deletePath}${month}`)
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const [year, month] = yearMonth

  return (
    <Container>
      <h1>{title}</h1>
      <FileUploadInputBox
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        files={files}
        isDragActive={isDragActive}
        removeFile={removeFile}
      />
      <Actions>
        <Button onClick={() => setFiles([])} type="warning">
          Clear
        </Button>

        <Row>
          <YearMonthInput
            year={year}
            month={month}
            setYearMonth={setYearMonth}
          />
        </Row>

        <Button onClick={deleteHardware}>Delete</Button>
        <Button onClick={submit} disabled={!files.length}>
          Submit
        </Button>
      </Actions>
    </Container>
  )
}

export const Hardware = (props) => (
  <FileUpload
    {...props}
    uploadPath="/api/monthly-statement/hardware/"
    deletePath="/api/monthly-statement/hardware/"
    title="Electronics"
  />
)

export const Taxi = (props) => (
  <FileUpload
    {...props}
    uploadPath="/api/monthly-statement/taxi/"
    deletePath="/api/monthly-statement/taxi/"
    title="Taxi"
  />
)

const StatutoryAccountingFileUpload = ({
  setIsSubmitting,
  setJobResult,
  title,
}) => {
  const [files, setFiles] = useState([])
  const [yearEntity, setYearEntity] = useState([
    currentDate.getUTCFullYear(),
    'vacuumlabs s.r.o.',
  ])
  const {closeModal, setModalChildren} = useContext(ModalContext)

  const addFiles = (newFiles) => {
    setFiles((files) => {
      return _.unionBy(files, newFiles, (x) => x.name)
    })
  }

  const removeFile = (file) => {
    setFiles((files) => {
      return _.without(files, file)
    })
  }
  const onDrop = (acceptedFiles) => {
    addFiles(acceptedFiles)
  }

  const submit = async () => {
    const [year, entity] = yearEntity

    const proceed = async () => {
      setIsSubmitting(true)
      setJobResult(
        (
          await submitFiles(
            files,
            'statutoryAccounting',
            `/api/statutory-accounting/${year}/${entity}`,
          )
        ).data,
      )
      setIsSubmitting(false)
      closeModal()
    }

    const result =
      (await axios.get(`/api/statutory-accounting/${year}/${entity}/count`))
        .data || {}

    if (Number(result.count) > 0) {
      setModalChildren(
        <YearEntityMismatchModalBody
          year={year}
          entity={entity}
          confirm={proceed}
          closeModal={closeModal}
        />,
      )
    } else {
      await proceed()
    }
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const [year, entity] = yearEntity

  return (
    <Container>
      <h1>{title}</h1>
      <FileUploadInputBox
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        files={files}
        isDragActive={isDragActive}
        removeFile={removeFile}
      />
      <Actions>
        <Button onClick={() => setFiles([])} type="warning">
          Clear
        </Button>
        <Row>
          <YearEntityInput
            year={year}
            entity={entity}
            setYearEntity={setYearEntity}
          />
        </Row>
        <Button onClick={submit} disabled={!files.length}>
          Submit
        </Button>
      </Actions>
    </Container>
  )
}

export const StatutoryAccounting = (props) => (
  <StatutoryAccountingFileUpload {...props} title="Statutory Accounting" />
)

class EmployeesCache {
  constructor() {
    this.yearMonth = ''
    this.employees = []
  }

  async get(year, month) {
    const yearMonth = formatYearMonth(year, month)
    if (this.yearMonth !== yearMonth) {
      const res = await axios.get(`/api/employee-data/${yearMonth}`)
      if (!res?.data?.success || !res?.data?.employees) return []

      this.employees = res.data.employees
      this.yearMonth = yearMonth
    }
    return this.employees
  }
}

export const Payslip = ({setIsSubmitting, setJobResult}) => {
  const [files, setFiles] = useState([])
  const [yearMonth, setYearMonth] = useState(monthBefore())
  const [entity, setEntity] = useState('vacuumlabs s.r.o.')
  const {closeModal, setModalChildren} = useContext(ModalContext)
  const employees = new EmployeesCache()

  const setYearMonthEntity = ([year, month, entity]) => {
    setYearMonth([year, month])
    setEntity(entity)
  }

  const addFiles = (newFiles) => {
    setFiles((files) => {
      return _.unionBy(files, newFiles, (x) => x.name)
    })
  }

  const removeFile = (file) => {
    setFiles((files) => {
      return _.without(files, file)
    })
  }

  const onDrop = (acceptedFiles) => {
    Promise.all(
      acceptedFiles.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader()
          reader.onload = function (e) {
            file.contents = e.target.result
            resolve(file)
          }
          reader.readAsText(file)
        })
      }),
    ).then((acceptedFiles) => {
      addFiles(acceptedFiles)
    })
  }

  const submit = async () => {
    const [year, month] = yearMonth

    const proceed = async () => {
      setIsSubmitting(true)
      setJobResult(
        (
          await submitFiles(
            files,
            'payslip',
            `/api/payslip/${year}/${month}/${entity}`,
          )
        ).data,
      )
      setIsSubmitting(false)
      closeModal()
    }

    const entities = (await axios.get('/api/entities')).data?.entities
    const entityId = entities ? entities[entity]?.id : null

    const alreadyCheckedPayslips = {} // to check payslip key duplicity in this whole batch
    const checkResult = await Promise.all(
      files.map(async (file) => {
        const [errors, warnings] = checkPayslips({
          payslips: parsePayslip(file, parse),
          year,
          month,
          employer: entity,
          relevantEmployees: (await employees.get(year, month)).filter(
            ({employer}) => employer === entityId,
          ),
          alreadyCheckedPayslips,
        })
        return {
          document: file.name,
          errors,
          warnings,
        }
      }),
    )
    const failedFiles = checkResult.filter(({errors}) => errors.length > 0)

    if (failedFiles.length > 0) {
      setJobResult({success: false, failedFiles})
    } else {
      const filesWithWarnings = checkResult.filter(
        ({warnings}) => warnings.length > 0,
      )

      if (filesWithWarnings.length > 0) {
        setModalChildren(
          <UploadDataWarningsModalBody
            filesWithWarnings={filesWithWarnings}
            confirm={proceed}
            closeModal={closeModal}
          />,
        )
      } else {
        await proceed()
      }
    }
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
  const [year, month] = yearMonth

  return (
    <Container>
      <h1>Payslip</h1>
      <FileUploadInputBox
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        files={files}
        isDragActive={isDragActive}
        removeFile={removeFile}
      />
      <Actions>
        <Button onClick={() => setFiles([])} type="warning">
          Clear
        </Button>
        <Row>
          <YearMonthEntityInput
            year={year}
            month={month}
            entity={entity}
            setYearMonthEntity={setYearMonthEntity}
            countries={['sk']}
          />
        </Row>
        <Button onClick={submit} disabled={!files.length}>
          Submit
        </Button>
      </Actions>
    </Container>
  )
}
