import React from 'react'
import {Container, Logo, Line} from './styled/Header.styled'

const Header = () => {
  return (
    <Container>
      <Line />
      <Logo href="/" className="header__logo">
        Ledger Vacuumlabs
      </Logo>
    </Container>
  )
}

export default Header
