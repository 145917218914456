import React, {useState, useEffect} from 'react'
import axios from 'axios'
import Button from '../Common/Button'
import Actions from '../Common/Actions'
import Accounts from './Accounts'
import {Container} from './styled/Revolut.styled'

const Revolut = ({setIsSubmitting, setJobResult}) => {
  const [isManagingAccounts, setIsManagingAccounts] = useState(false)
  const [accounts, setAccounts] = useState([])

  const loadExistingAccounts = async () => {
    const result = (await axios.get('/api/revolut/accounts')).data
    setAccounts(result.accounts)
  }
  const accountLength = (accounts || []).length
  useEffect(() => {
    loadExistingAccounts()
  }, [accountLength])

  const loadRevolutTransactions = async () => {
    setIsSubmitting(true)
    setJobResult((await axios.post('/api/revolut/sync')).data)
    setIsSubmitting(false)
  }

  return (
    <Container>
      <h1>Revolut</h1>
      <div>
        <Actions>
          <Button
            onClick={() => setIsManagingAccounts(!isManagingAccounts)}
            type="warning"
          >
            {isManagingAccounts ? 'Stop managing accounts' : 'Manage accounts'}
          </Button>
          <Button
            disabled={!accounts || accounts.length === 0}
            onClick={loadRevolutTransactions}
          >
            Load transactions
          </Button>
        </Actions>
      </div>
      {isManagingAccounts && (
        <Accounts accounts={accounts} onAccountDeleted={loadExistingAccounts} />
      )}
    </Container>
  )
}

export default Revolut
