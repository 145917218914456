import React from 'react'
import _ from 'lodash'
import {ChangedCell, Table, TableHolder} from './styled/EmployeeTable.styled'

const DetailsRow = ({details}) =>
  details.map((row, i) => (
    <tr key={i}>
      <td />
      {row.map((cell, j) => {
        if (_.isArray(cell)) {
          return <ChangedCell key={j}>{`${cell[0]} => ${cell[1]}`}</ChangedCell>
        }
        if (cell == null) {
          return <td key={j}>null</td>
        }
        return <td key={j}>{cell.toString()}</td>
      })}
    </tr>
  ))

const DetailsTable = ({details, header, name}) => {
  if (details && details.length > 0) {
    return (
      <>
        <thead>
          <tr>
            <th>{name}</th>
            {header.map((title, i) => (
              <th key={i}>{title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <DetailsRow details={details} />
        </tbody>
      </>
    )
  }
  return null
}

export const EmployeeTable = ({diff, header}) => {
  const {added, deleted, edited} = diff
  const hasChanges = _.some(diff, (details) => details && details.length > 0)
  if (hasChanges) {
    return (
      <TableHolder>
        <Table>
          <DetailsTable details={added} header={header} name="Added" />
          <DetailsTable details={deleted} header={header} name="Deleted" />
          <DetailsTable details={edited} header={header} name="Edited" />
        </Table>
      </TableHolder>
    )
  }
  return null
}

export default EmployeeTable
