import styled from 'styled-components'

const bgColors = {
  primary: '#f07',
  warning: '#cc6c14',
}

export const StyledButton = styled.button`
  background: ${({disabled, type}) => (disabled ? '#ccc' : bgColors[type])};
  outline: none;
  border: none;
  color: #fff;
  padding: 15px;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: ${({disabled}) => (disabled ? '' : 'pointer')};
`
