import React from 'react'
import {
  ButtonContainer,
  Container,
  ItemContainer,
  ItemHeader,
  ErrorsContainer,
  DocumentName,
  Errors,
} from './styled/UploadDataWarningsModalBody.styled'
import Button from '../Common/Button'

const UploadDataWarningsModalBody = ({
  filesWithWarnings,
  confirm,
  closeModal,
}) => (
  <Container>
    <h2>Warnings</h2>
    {filesWithWarnings.map(({document, warnings}) => (
      <ItemContainer key={document}>
        <ItemHeader>
          <DocumentName>{document}</DocumentName>
        </ItemHeader>
        <ErrorsContainer>
          <Errors>
            <thead>
              <tr>
                <th>Row / Id</th>
                <th>Warnings</th>
              </tr>
            </thead>
            <tbody>
              {warnings.map(({id, message}) => {
                if (!Array.isArray(id)) id = [id]
                const [key, url] = id
                const link =
                  url == null ? (
                    key
                  ) : (
                    <a href={url} target="_blank" rel="noopener noreferrer">
                      {key}
                    </a>
                  )
                return (
                  <tr key={key}>
                    <td>{link}</td>
                    <td>{message}</td>
                  </tr>
                )
              })}
            </tbody>
          </Errors>
        </ErrorsContainer>
      </ItemContainer>
    ))}

    <h2>Do you want to upload files?</h2>
    <ButtonContainer>
      <Button onClick={confirm}>Yes</Button>
      <Button onClick={closeModal} type="warning">
        Cancel
      </Button>
    </ButtonContainer>
  </Container>
)

export default UploadDataWarningsModalBody
