import axios from 'axios'
import React, {useCallback, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import _ from 'lodash'
import {Container} from './styled/HomePage.styled'
import {submitFiles} from '../../utils'
import FullPageLoader from '../Common/FullPageLoader'
import Button from '../Common/Button'
import Actions from '../Common/Actions'
import JobResult from './JobResult'
import Revolut from '../Revolut/Revolut'
import EmployeeApproval from '../EmployeeData/EmployeeApproval'
import ProjectData from '../ProjectData/ProjectData'
import Constants from '../Constants'
import {
  Hardware,
  Taxi,
  StatutoryAccounting,
  Payslip,
} from '../FileUpload/FileUpload'
import Stripe from '../Stripe'
import {FileUploadInputBox} from '../utils'

const HomePage = () => {
  const [files, setFiles] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [jobResult, setJobResult] = useState(undefined)

  const addFiles = (newFiles) => {
    setFiles((files) => {
      return _.unionBy(files, newFiles, (x) => x.name)
    })
  }

  const removeFile = (file) => {
    setFiles((files) => {
      return _.without(files, file)
    })
  }

  const onDrop = useCallback((acceptedFiles) => {
    addFiles(acceptedFiles)
  }, [])

  const submit = async () => {
    setIsSubmitting(true)

    setJobResult((await submitFiles(files, 'sheets', '/api/upload')).data)
    setIsSubmitting(false)
  }

  const loadInvoices = async () => {
    setIsSubmitting(true)

    setJobResult((await axios.post('/api/invoices')).data)
    setIsSubmitting(false)
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  if (isSubmitting) return <FullPageLoader />
  else if (jobResult) return <JobResult {...jobResult} />

  return (
    <Container>
      <FileUploadInputBox
        getRootProps={getRootProps}
        getInputProps={getInputProps}
        files={files}
        isDragActive={isDragActive}
        removeFile={removeFile}
      />
      <Actions>
        <Button onClick={() => setFiles([])} type="warning">
          Clear
        </Button>

        <Button onClick={loadInvoices}>Load invoices</Button>

        <Button onClick={submit} disabled={!files.length}>
          Submit
        </Button>
      </Actions>
      <Revolut setIsSubmitting={setIsSubmitting} setJobResult={setJobResult} />
      <Stripe setIsSubmitting={setIsSubmitting} setJobResult={setJobResult} />
      <Constants
        setIsSubmitting={setIsSubmitting}
        setJobResult={setJobResult}
      />
      <EmployeeApproval
        setIsSubmitting={setIsSubmitting}
        setJobResult={setJobResult}
      />
      <ProjectData
        setIsSubmitting={setIsSubmitting}
        setJobResult={setJobResult}
      />
      <Hardware setIsSubmitting={setIsSubmitting} setJobResult={setJobResult} />
      <Taxi setIsSubmitting={setIsSubmitting} setJobResult={setJobResult} />
      <StatutoryAccounting
        setIsSubmitting={setIsSubmitting}
        setJobResult={setJobResult}
      />
      <Payslip setIsSubmitting={setIsSubmitting} setJobResult={setJobResult} />
    </Container>
  )
}

export default HomePage
