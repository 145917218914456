import React from 'react'
import axios from 'axios'
import styled from 'styled-components'
import Button from './Common/Button'

export const Container = styled.div`
  text-align: center;
  margin: 0 0 50px 0;
`
export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`

const Constants = ({setIsSubmitting, setJobResult}) => {
  const loadCards = async () => {
    setIsSubmitting(true)
    setJobResult((await axios.post('/api/cards')).data)
    setIsSubmitting(false)
  }

  const loadEntities = async () => {
    setIsSubmitting(true)
    setJobResult((await axios.post('/api/entities')).data)
    setIsSubmitting(false)
  }

  const loadBankAccounts = async () => {
    setIsSubmitting(true)
    setJobResult((await axios.post('/api/bank-accounts')).data)
    setIsSubmitting(false)
  }

  return (
    <Container>
      <h1>Cards, Entities and Bank Accounts</h1>
      <Actions>
        <Button onClick={loadEntities}>Load Entities</Button>
        <Button onClick={loadBankAccounts}>Load Bank Accounts</Button>
        <Button onClick={loadCards}>Load Cards & Update Transactions</Button>
      </Actions>
    </Container>
  )
}

export default Constants
