import React from 'react'
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader'
import {Container} from './styled/FullPageLoader.styled'

const FullPageLoader = () => {
  return (
    <Container>
      <ClimbingBoxLoader color={'#f07'} size={50} />
    </Container>
  )
}

export default FullPageLoader
