import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 10px;
`

export const Label = styled.label`
  display: block;
`

export const TextInput = styled.input`
  border-radius: 5px;
  padding: 5px;
  margin-top: 5px;
`
