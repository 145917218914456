import React, {useState, useEffect} from 'react'
import {Select} from './styled/YearMonthInput.styled'
import _ from 'lodash/fp'
import axios from 'axios'

export const years = _.range(2014, new Date().getFullYear() + 1)

const YearEntityInput = ({year, entity, setYearEntity}) => {
  const [entities, setEntities] = useState([])

  const loadExistingEntities = async () => {
    const result = (await axios.get('/api/entities')).data
    setEntities(
      Object.values(result.entities || {})
        .map((entity) => entity.name)
        .sort((a, b) => a.localeCompare(b)),
    )
  }

  const entitiesLength = (entities || []).length
  useEffect(() => {
    loadExistingEntities()
  }, [entitiesLength])

  return (
    <>
      <Select
        value={year}
        onChange={(e) => setYearEntity([e.target && e.target.value, entity])}
      >
        {years.map((year, i) => (
          <option key={i + 1} value={year}>
            {year}
          </option>
        ))}
      </Select>
      <Select
        value={entity}
        onChange={(e) => setYearEntity([year, e.target && e.target.value])}
      >
        {(entities || ['vacuumlabs s.r.o.']).map((entity, i) => (
          <option key={i + 1} value={entity}>
            {entity}
          </option>
        ))}
      </Select>
    </>
  )
}

export default YearEntityInput
