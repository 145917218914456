import styled from 'styled-components'

export const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5em;
  width: 100%;
`

export const ItemContainer = styled.div`
  background: #dedede;
  margin-bottom: 10px;
  min-width: 60%;
`

export const ItemHeader = styled.div`
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
`

export const DocumentName = styled.div`
  font-weight: 600;
`

export const ErrorsContainer = styled.div`
  margin-top: 30px;
  padding: 20px;
`

export const Errors = styled.table`
  width: 100%;

  td {
    padding: 10px;
  }

  tbody tr:hover {
    background: #ccc;
  }

  a {
    display: block;
    color: #f07;
  }

  a:hover {
    text-decoration: underline;
  }
`
