import React from 'react'

import Button from '../Common/Button'

import {Container, ButtonContainer} from './styled/ConfirmationModalBody.styled'

const ConfirmationModalBody = ({closeModal, confirm}) => (
  <Container>
    <h2>Are you sure?</h2>
    <ButtonContainer>
      <Button onClick={confirm}>Yes</Button>
      <Button onClick={closeModal} type="warning">
        Cancel
      </Button>
    </ButtonContainer>
  </Container>
)

export default ConfirmationModalBody
