import styled from 'styled-components'

export const Container = styled.div`
  text-align: center;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2em;
`
