import styled from 'styled-components'

export const Table = styled.table`
  background-color: #dedede;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 10px;

  td,
  th {
    border: 1px solid darkgrey;
    padding: 5px;
    margin: 0;
  }

  td {
    text-align: right;
  }

  th:first-child {
    text-align: left;
  }
`

export const TableHolder = styled.div`
  overflow: auto;
  max-width: 100%;
`

export const ChangedCell = styled.td`
  background-color: teal;
  color: white;
`
