import React from 'react'
import {ButtonContainer, Container} from './styled/DateMismatchModalBody.styled'
import Button from '../Common/Button'

const YearEntityMismatchModalBody = ({year, entity, confirm, closeModal}) => (
  <Container>
    <h2>
      Records for selected year ({year}) and entity ({entity}) already exist.
    </h2>
    <h2>Do you want to rewrite existing records?</h2>
    <ButtonContainer>
      <Button onClick={confirm}>Yes</Button>
      <Button onClick={closeModal} type="warning">
        Cancel
      </Button>
    </ButtonContainer>
  </Container>
)

export default YearEntityMismatchModalBody
