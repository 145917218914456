import React, {useState} from 'react'
import axios from 'axios'

import Button from '../Common/Button'
import Actions from '../Common/Actions'
import InputGroup from '../Common/InputGroup'

import {Container, Column, Row} from './styled/index.styled'

const text = {
  accountName: 'Account name',
  secretKey: 'Secret API key',
}

const Stripe = ({setIsSubmitting, setJobResult}) => {
  const [isAddingAccount, setIsAddingAccount] = useState(false)
  const [account, setAccount] = useState('')
  const [apiKey, setApiKey] = useState('')

  const storeAccount = async () => {
    setIsSubmitting(true)
    setJobResult(
      (await axios.post('api/stripe/add_account', {account, apiKey})).data,
    )
    setIsSubmitting(false)
  }

  const loadStripeTransactions = async () => {
    setIsSubmitting(true)
    setJobResult((await axios.post('/api/stripe/sync')).data)
    setIsSubmitting(false)
  }

  return (
    <Container>
      <h1>Stripe</h1>
      <div>
        <Actions>
          <Button
            onClick={() => setIsAddingAccount(!isAddingAccount)}
            type="warning"
          >
            Add stripe account
          </Button>
          <Button onClick={loadStripeTransactions}>Load transactions</Button>
        </Actions>
      </div>
      {isAddingAccount && (
        <Row>
          <Column>
            <InputGroup
              label={text.accountName}
              name={text.accountName}
              value={account}
              onChange={setAccount}
            />
          </Column>
          <Column>
            <InputGroup
              label={text.secretKey}
              name={text.secretKey}
              value={apiKey}
              onChange={setApiKey}
            />
          </Column>
          <Button onClick={storeAccount} disabled={!account || !apiKey}>
            Add account to database
          </Button>
        </Row>
      )}
    </Container>
  )
}

export default Stripe
