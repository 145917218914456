import styled from 'styled-components'

export const Container = styled.div`
  max-width: 700px;
  margin: 0 auto;
`

export const InputBox = styled.div`
  width: 100%;
  height: 500px;
  overflow-y: auto;

  background: #ddd;

  padding: 15px;

  outline: 4px dashed #ccc;
  outline-offset: -10px;
`

export const File = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;
  user-select: none;

  & i {
    visibility: hidden;
    padding: 0 10px;
  }

  &:hover {
    background-color: #ff2289;
    color: #fff;

    & i {
      visibility: visible;
      cursor: pointer;
    }
  }
`

export const Instructions = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const InstructionText = styled.h2`
  color: #555;
  margin-top: 30px;
`
