import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ItemContainer = styled.div`
  background: #dedede;
  margin-bottom: 10px;
  min-width: 60%;
`

export const ItemHeader = styled.div`
  padding: 15px 25px;
  display: flex;
  justify-content: space-between;
`

export const DocumentName = styled.div`
  font-weight: 600;
`

export const ErrorsContainer = styled.div`
  margin-top: 30px;
  padding: 20px;
`

export const Errors = styled.table`
  width: 100%;

  td {
    padding: 10px;
  }

  tbody tr:hover {
    background: #ccc;
  }

  a {
    display: block;
    color: #f07;
  }

  a:hover {
    text-decoration: underline;
  }
`

export const Actions = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;

  button {
    margin: auto 1em;
  }
`

export const TraceHolder = styled.div`
  width: 100%;
  overflow: auto;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`

export const Trace = styled.pre`
  display: flex;
  whitespace: break-spaces;
  font-size: 0.8em;
  background: #fff;
  padding: 10px;
  margin: auto;
`
