import React from 'react'
import {Select} from './styled/YearMonthInput.styled'

// prettier-ignore
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

export const monthBefore = () => {
  const currentDate = new Date()
  const currentMonth = currentDate.getMonth()
  const currentYear = currentDate.getFullYear()
  return [
    `${currentMonth ? currentYear : currentYear - 1}`,
    `${currentMonth || 12}`,
  ]
}

const YearMonthInput = ({year, month, setYearMonth}) => {
  const maxYear = new Date().getFullYear() + 1
  const years = []
  for (let i = 2020; i <= maxYear; i++) years.push(i)
  return (
    <>
      <Select
        value={year}
        onChange={(e) => setYearMonth([e.target && e.target.value, month])}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
      <Select
        value={month}
        onChange={(e) => setYearMonth([year, e.target && e.target.value])}
      >
        {months.map((month, i) => (
          <option key={i + 1} value={i + 1}>
            {month}
          </option>
        ))}
      </Select>
    </>
  )
}

export default YearMonthInput
