import React, {useState, useContext} from 'react'
import _ from 'lodash'
import axios from 'axios'
import Button from '../Common/Button'
import YearMonthInput, {monthBefore} from '../Common/YearMonthInput'
import {
  Container,
  Column,
  Row,
  YearMonthLabel,
  ErrorMessage,
} from './styled/ProjectData.styled.js'
import {ModalContext} from '../Common/Modal'
import ConfirmationModalBody from '../Common/ConfirmationModalBody'

const getYearMonth = (yearMonth) => {
  const [year, month] = yearMonth

  return `${year}-${month.toString().padStart(2, '0')}`
}

const ProjectData = ({setIsSubmitting, setJobResult}) => {
  const {closeModal, setModalChildren} = useContext(ModalContext)
  const [fromYearMonth, setFromYearMonth] = useState(monthBefore())
  const [toYearMonth, setToYearMonth] = useState(monthBefore())

  const getFromTo = () => {
    const from = getYearMonth(fromYearMonth)
    const to = getYearMonth(toYearMonth)
    return {from, to}
  }

  const confirm = async (confirmationId) => {
    setIsSubmitting(true)
    const {success, message, stackTrace} = (
      await axios.post(`/api/confirmation/${confirmationId}`)
    ).data
    setJobResult({
      success,
      message,
      stackTrace,
      diff: undefined,
      header: undefined,
      actions: undefined,
      title: undefined,
    })
    setIsSubmitting(false)
  }

  const getActions = (confirmationId) => {
    if (confirmationId !== null) {
      const openConfirmationModal = () => {
        const onConfirm = () => {
          confirm(confirmationId)
          closeModal()
        }
        setModalChildren(
          <ConfirmationModalBody closeModal={closeModal} confirm={onConfirm} />,
        )
      }

      return [
        ['Confirm overwrite', openConfirmationModal],
        ['Cancel', () => window.location.reload()],
      ]
    }
    return undefined
  }

  const hasValidFromToRange = () => {
    const [fromYear, fromMonth] = fromYearMonth
    const [toYear, toMonth] = toYearMonth

    const from = Number(fromYear) * 12 + Number(fromMonth)
    const to = Number(toYear) * 12 + Number(toMonth)
    return to >= from
  }

  const loadProjectData = async () => {
    setIsSubmitting(true)
    const {from, to} = getFromTo()
    const data = (await axios.post(`/api/project-data/${from}/${to}`)).data
    const {success, message, diff, errors, confirmationId} = data

    const actions = getActions(confirmationId)
    const hasDifferences = _.some(_.values(diff), (project) =>
      _.some(
        _.values(project.diff),
        (addOrEditOrDel) => !_.isEmpty(addOrEditOrDel),
      ),
    )

    const title = hasDifferences && 'Warning'
    const errorMessage =
      hasDifferences || !success
        ? message || 'There are differences between GL and Seal'
        : message || 'Nothing has Changed'

    setJobResult({
      success,
      title,
      message: errorMessage,
      diff,
      errors,
      actions,
    })
    setIsSubmitting(false)
  }

  const [fromYear, fromMonth] = fromYearMonth
  const [toYear, toMonth] = toYearMonth

  const isValidRange = hasValidFromToRange()

  return (
    <Container>
      <h1>Seal Projects</h1>
      <Row>
        <Column>
          <Row>
            <YearMonthLabel>From:</YearMonthLabel>
            <YearMonthInput
              year={fromYear}
              month={fromMonth}
              setYearMonth={setFromYearMonth}
            />
          </Row>
          <Row>
            <YearMonthLabel>To:</YearMonthLabel>
            <YearMonthInput
              year={toYear}
              month={toMonth}
              setYearMonth={setToYearMonth}
            />
          </Row>
        </Column>
        <Column>
          <Button disabled={!isValidRange} onClick={loadProjectData}>
            Load Project Data
          </Button>
        </Column>
      </Row>
      {!isValidRange && <ErrorMessage>Invalid from-to range</ErrorMessage>}
    </Container>
  )
}

export default ProjectData
