"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeEntity = void 0;
function normalizeEntity(str) {
    return (str || '')
        .trim()
        .toLowerCase()
        .replace(/s\.?\s*r\.?\s*o\.?$/, 's.r.o.')
        .replace(/j\.?\s*s\.?\s*a\.?$/, 'j.s.a.')
        .replace(/k\.?\s*f\.?\s*t\.?$/, 'kft.')
        .replace(/o\.?\s*[uü]\.?$/, 'oü');
}
exports.normalizeEntity = normalizeEntity;
