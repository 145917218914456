import axios from 'axios'

export const submitFiles = (files, fieldName, route) => {
  const formData = new FormData()

  files.forEach((file) => {
    formData.append(fieldName, file)
  })

  return axios.post(route, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}
