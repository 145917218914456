import styled from 'styled-components'

export const Container = styled.div`
  margin-bottom: 20px;
`

export const Table = styled.table`
  text-align: center;
`

export const TableRow = styled.tr`
  padding: 10px;
`

export const TableData = styled.td`
  padding: 10px;
`

export const SmallP = styled.p`
  font-size: 12px;
  font-style: italic;
`
